var mod_testimonials = [];

function mod_testimonials_start( el, interval ) {
	var modID = mod_testimonials.length;
	mod_testimonials[ modID ] = {
		'el': $( el ),
		'timer': null,
		'interval': interval,
	}
	mod_testimonials[ modID ]['timer'] = setInterval(function(){
		var items = mod_testimonials[ modID ]['el'].find('blockquote');
		if (0 < items.length) {
			var cur = items.filter('.on');
			if (0 == cur.length) {
				cur = items.last();
			}
			var next = cur.next();
			if (0 == next.length) {
				next = items.first();
			}

			cur.removeClass('on');
			next.addClass('on');
			if ( cur.index() == next.index() ) {
				clearInterval( mod_testimonials[ modID ]['timer'] );
			}
		}
	}, mod_testimonials[ modID ]['interval']);
}

