
var mod_slideshows_timers = [];

function mod_slideshows_next_idx($s, direction) {
	var idx = 0;
	var max = $s.find('figure').length;
	if (direction == 0) {
		idx = Math.floor( Math.random(max+1) );
	} else {
		var $cur = $s.find('figure.active');
		if ($cur.length > 0) {
			idx = $cur.index() + direction;
		}
	}
	while (0 > idx) {
		idx += max;
	}
	idx %= max;
	return idx;
}

function mod_slideshows_animate_to($s, idx) {
	var $next = $s.find('figure:nth-of-type(' +  (idx+1) + ')');
	var $prev = $s.find('figure.previous');
	var $cur = $s.find('figure.active');
	if ($next && $cur) {
		if ($next != $cur) {
			$cur.add($prev).toggleClass('previous');
			$next.add($cur).toggleClass('active');
		}
	}
}

function mod_slideshows_init() {

	if (0 < $('.mod_slideshows').length) {

		$('.mod_slideshows')
		.each(function(idx, el){
			var $el = $( el );
//TODO:
			$el
			// .find('figure')
			// 	.first().addClass('on')
			// .end()
			.find('.nav_prev, .nav_next')
				.on('click', function(e){
					e.preventDefault();
					e.stopImmediatePropagation();
					var $prev = $el.find('figure.previous');
					var $cur = $el.find('figure.active');
					if ( $(this).hasClass('nav_prev') ) {
						var $next = $cur.prev('figure');
						if ($next.length==0) {
							$next = $('#mod_media figure:last-of-type');
						}
					} else {
						var $next = $cur.next('figure');
						if ($next.length==0) {
							$next = $('#mod_media figure:first-of-type');
						}
					}
					if ($next && $next != $cur) {
						clearTimeout( mod_media_play_timer );
						var $el = $cur.children().first();
						if ($el.is('video,audio')) {
							$el[0].pause();
						} else if ($el.is('.youtube')) {
							($el.data('player')).pauseVideo();
						}
						$cur.add( $next ).toggleClass('on');
						$el = $next.children().first();
						if ($el.is('video,audio')) {
							$el[0].pause();
							$el[0].currentTime = 0;
							mod_media_play_timer = setTimeout(function(){
								// $el[0].play();
							}, 500);
						} else if ($el.is('.youtube')) {
							// ($el.data('player')).seekTo(0);
							mod_media_play_timer = setTimeout(function(){
								// ($el.data('player')).pauseVideo();
								// ($el.data('player')).playVideo();
							}, 500);
						}
					}
				})
			.end()

			.find('.dot')
				.on('click', function(e){
					e.preventDefault();
					e.stopImmediatePropagation();
					//TODO
				})
			.end()

			// Init switching 'active' & 'previous' classes
			//TODO

			//TODO: keep/check??
			.find('img')
				.after(
					$('<a href="#" />')
					.addClass('fullscreen fa fa-expand')
					.on('click', function(ev){
						ev.preventDefault();
						ev.stopImmediatePropagation();
						if ( $('body').hasClass('in-fullscreen') ) {
							leaveFullscreen();							
						} else {
							enterFullscreen( $(this).parent()[0] );
						}
					})
				)
			.end()
			.find('video, audio')
				.each(function(idx, el){
					el.pause();
					el.currentTime = 0;
					el.volume = 0.2;

					var $el = $( el );

					el.onpause = function() {
						$el.next().addClass('paused').find('.play').toggleClass('fa-play fa-pause');
					};
					el.onplay = function() {
						$el.next().removeClass('paused').find('.play').removeClass('fa-undo').toggleClass('fa-play fa-pause');
					};
					el.onended = function() {
						$el.next().find('.play').addClass('fa-undo');
					};
					el.ondurationchange = function() {
						var t = el.duration,
							w = 0,
							v = '';

						w = Math.floor(t / 3600);
						t -= (w * 3600);
						if (w>0) {
							$el.next().find('.time').data('show-hours', true)
							v = v + (w<10?'0':'') + w + ':';
						}

						w = Math.floor(t / 60);
						t -= (w * 60);
						v = v + (w<10?'0':'') + w + ':';
						
						w = Math.round(t);
						v = v + (w<10?'0':'') + w;

						$el.next()
						.find('.time').find('.duration')
							.html( v );
					};
					el.ontimeupdate = function() {
						var t = el.currentTime,
							w = 0,
							v = '';
						
						w = Math.floor(t / 3600);
						t -= (w * 3600);
						if ($el.next().find('.time').data('show-hours')) {
							v = v + (w<10?'0':'') + w + ':';
						}
						
						w = Math.floor(t / 60);
						t -= (w * 60);
						v = v + (w<10?'0':'') + w + ':';
						
						w = Math.round(t);
						v = v + (w<10?'0':'') + w;
						
						$el.next()
						.find('.time').find('.currentTime')
							.html( v )
						.end().end()
						.find('.position').find('div')
							.width( (t*100 / el.duration) + '%' );
					};
					el.onvolumechange = function() {
						$el.next()
						.find('.volume')
							.toggleClass('fa-volume-off', el.muted)
							.toggleClass('fa-volume-up', !el.muted)
						.end()
						.find('.volume-bar')
							.toggleClass('muted', el.muted)
							.find('div')
								.height( 100 * el.volume + '%');
					};

					$el.after(
						$('<div />')
						.addClass('controls paused')
						.on('click', function(ev){
							ev.preventDefault();
							ev.stopImmediatePropagation();
							$(this).find('.play').click();
						})
						.on('dblclick', function(ev){
							ev.preventDefault();
							ev.stopImmediatePropagation();
							$(this).find('.fullscreen').click();
						})
						.append(
							$('<a href="#" />').addClass('play fa fa-play')
								.on('click', function(ev){
									ev.preventDefault();
									ev.stopImmediatePropagation();
									if (el.paused) {
										el.play();
									} else {
										el.pause();
									}
								})
								.on('focus', function(){
									$(window).on('keydown.video',function(ev2){
										if ( ev2.which == 13 ) {
											ev2.preventDefault();
											ev2.stopImmediatePropagation();
											if (el.paused) {
												el.play();
											} else {
												el.pause();
											}
										} else {
											mod_media_keys_handler( el, ev2 );
										}
									});
								})
								.on('blur', function(){
									$(window).off('.video');
								})
						)
						.append(
							$('<a href="#" />').addClass('fullscreen fa fa-expand')
								.on('click', function(ev){
									ev.preventDefault();
									ev.stopImmediatePropagation();
									if ( $('body').hasClass('in-fullscreen') ) {
										leaveFullscreen();							
									} else {
										enterFullscreen( $(el).parent().toggleClass('in-fullscreen')[0] );
										$(window).on('keydown.fullscreen-video',function(ev2){
											if ( ev2.which == 13 ) {
												ev2.preventDefault();
												ev2.stopImmediatePropagation();
												if (el.paused) {
													el.play();
												} else {
													el.pause();
												}
											} else {
												mod_media_keys_handler( el, ev2 );
											}
										});
									}
								})
								.on('focus', function(){
									$(window).on('keydown.video',function(ev2){
										mod_media_keys_handler( el, ev2 );
									});
								})
								.on('blur', function(){
									$(window).off('.video');
								})
						)
						.append(
							$('<div />').addClass('time')
								.html('<div class="duration" /><div class="currentTime" />')
						)
						.append(
							$('<a href="#" />').addClass('position')
								.html('<div/>')
								.on('click', function(ev){
									ev.preventDefault();
									ev.stopImmediatePropagation();
									var x = ev.pageX - $(this).offset().left,
										w = parseInt($(this).width());
									el.currentTime = Math.max(0, Math.min(1, x/w)) * el.duration;
								})
								.on('focus', function(){
									$(window).on('keydown.video',function(ev2){
										if ( ev2.which == 13 ) {
											ev2.preventDefault();
											ev2.stopImmediatePropagation();
											if (el.paused) {
												el.play();
											} else {
												el.pause();
											}
										} else {
											mod_media_keys_handler( el, ev2 );
										}
									});
								})
								.on('blur', function(){
									$(window).off('.video');
								})
						)
						.append(
							$('<a href="#" />').addClass('volume-bar')
								.html('<div/>')
								.on('click', function(ev){
									ev.preventDefault();
									ev.stopImmediatePropagation();
									el.muted = false;
									var y = ev.pageY - $(this).offset().top,
										h = parseInt($(this).height());
									el.volume = Math.max(0, Math.min(1, (h-y)/h));
								})
								.on('focus', function(){
									$(window).on('keydown.video',function(ev2){
										if ( ev2.which == 13 ) {
											ev2.preventDefault();
											ev2.stopImmediatePropagation();
											if (el.paused) {
												el.play();
											} else {
												el.pause();
											}
										} else {
											mod_media_keys_handler( el, ev2 );
										}
									});
								})
								.on('blur', function(){
									$(window).off('.video');
								})
						)
						.append(
							$('<a href="#" />').addClass('volume fa fa-volume-up')
								.on('click', function(ev){
									ev.preventDefault();
									ev.stopImmediatePropagation();
									el.muted = !el.muted;
								})
								.on('focus', function(){
									$(window).on('keydown.video',function(ev2){
										mod_media_keys_handler( el, ev2 );
									});
								})
								.on('blur', function(){
									$(window).off('.video');
								})
						)
					);
				})
			.end();
		});


	}
	
}
