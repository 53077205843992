

var mod_media_play_timer;

/*
 * Inspired by: https://gist.github.com/ludo6577/53691fd3e71204f2ff36
 */
function isInFullscreen() {
	var tmp = document.fullScreenElement || document.mozFullScreen || document.webkitIsFullScreen || document.msIsFullScreen;
	return tmp;
}
function enterFullscreen(el) {
	var element = el || document.body;
	var requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullscreen || element.msRequestFullScreen;
	
	if (requestMethod) { // Native full screen.
		requestMethod.call(element);
	} else if (typeof window.ActiveXObject !== "undefined") { // Older IE.
		var wscript = new ActiveXObject("WScript.Shell");
		if (wscript !== null) {
			wscript.SendKeys("{F11}");
		}
	}
}
function leaveFullscreen() {
	if ( isInFullscreen() ) {
		var element = document;
		var requestMethod = element.cancelFullScreen || element.webkitCancelFullScreen || element.mozCancelFullScreen || element.msCancelFullscreen || element.msCancelFullScreen;
	
		if (requestMethod) { // Native full screen.
			requestMethod.call(element);
		} else if (typeof window.ActiveXObject !== "undefined") { // Older IE.
			var wscript = new ActiveXObject("WScript.Shell");
			if (wscript !== null) {
				wscript.SendKeys("{F11}");
			}
		}
	}
}
function checkFullScreen() {
	// window.outerWidth should work, but is bugged
	// if ( window.outerWidth == screen.width && window.outerHeight == screen.height ) {
	if ( $(window).width() == screen.width && $(window).height() == screen.height ) {
        // fullscreen
    }
}
/**
* Get YouTube ID from various YouTube URL
* @author: takien
* @url: http://takien.com
* For PHP YouTube parser, go here http://takien.com/864
*/

function YouTubeGetID(url) {
	var ID = '';
	url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
	if (url[2] !== undefined) {
		ID = url[2].split(/[^0-9a-z_\-]/i);
		ID = ID[0];
	} else {
		ID = url;
	}
	return ID;
}

function onYouTubeIframeAPIReady() {
	$('#mod_media')
	.find('div.youtube')
		.each(function(idx, el){
			var $el = $(el);
			var player = new YT.Player($el.attr('id'), {
				//height: '390',
				//width: '640',
				videoId: YouTubeGetID( $el.data('video-id') ),
				playerVars: {
					origin: window.location.hostname,
					enablejsapi: 1,
					modestbranding: 1,
					showinfo: 0,
					rel: 0,
				},
				events: {
					'onReady': function(e){
						$( e.target.getIframe() ).data('player', e.target);
					},
					//'onStateChange': function(e){},
				}
			});
		})
	.end();
}
function mod_media_keys_handler(el, ev2) {
	if (ev2.which == 37 || ev2.which == 39 || ev2.which == 38 || ev2.which == 40 || ev2.which == 32 || ev2.which == 77) {
		ev2.preventDefault();
		ev2.stopImmediatePropagation();
		if (ev2.which == 39) {
			el.currentTime = Math.min(el.duration, el.currentTime+0.1*el.duration);
		} else if (ev2.which == 37) {
			el.currentTime = Math.max(0, el.currentTime-0.1*el.duration);
		} else if (ev2.which == 32) {
			if (el.paused) {
				el.play();
			} else {
				el.pause();
			}
		} else if (ev2.which == 38 || ev2.which == 40) {
			el.muted = false;
			if (ev2.which == 38) {
				el.volume = Math.min(1, el.volume+0.1);
			} else if (ev2.which == 40) {
				el.volume = Math.max(0, el.volume-0.1);
			}
		} else if (ev2.which == 77) {
			el.muted = !el.muted;
		}
	}
}



function mod_media_init() {

	if (0 < $('#mod_media').length) {

		// $(window).on('resize', function(){
		// 	checkFullScreen();
		// });
		$(document).on('webkitfullscreenchange mozfullscreenchange MSFullscreenChange fullscreenchange', function(){
			var inFS = isInFullscreen();
			
			$('body').toggleClass('in-fullscreen', inFS);
			$('#mod_media .fullscreen')
				.toggleClass('fa-compress', inFS)
				.toggleClass('fa-expand', !inFS);
				
			if (!inFS) {
				$(window).off('.fullscreen-video');
			}
		});

		if ($('#mod_media').find('div.youtube').length > 0) {
			var tag = document.createElement('script');
			tag.src = "https://www.youtube.com/iframe_api";
			var firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
		}

		$('#mod_media')
		// .find('figure')
		// 	.first().addClass('on')
		// .end()
		.find('.nav_prev, .nav_next')
			.on('click', function(e){
				e.preventDefault();
				e.stopImmediatePropagation();
				var $cur = $('#mod_media figure.on');
				if ( $(this).hasClass('nav_prev') ) {
					var $next = $cur.prev('figure');
					if ($next.length==0) {
						$next = $('#mod_media figure:last-of-type');
					}
				} else {
					var $next = $cur.next('figure');
					if ($next.length==0) {
						$next = $('#mod_media figure:first-of-type');
					}
				}
				if ($next && $next != $cur) {
					clearTimeout( mod_media_play_timer );
					var $el = $cur.children().first();
					if ($el.is('video,audio')) {
						$el[0].pause();
					} else if ($el.is('.youtube')) {
						($el.data('player')).pauseVideo();
					}
					$cur.add( $next ).toggleClass('on');
					$el = $next.children().first();
					if ($el.is('video,audio')) {
						$el[0].pause();
						$el[0].currentTime = 0;
						mod_media_play_timer = setTimeout(function(){
							// $el[0].play();
						}, 500);
					} else if ($el.is('.youtube')) {
						// ($el.data('player')).seekTo(0);
						mod_media_play_timer = setTimeout(function(){
							// ($el.data('player')).pauseVideo();
							// ($el.data('player')).playVideo();
						}, 500);
					}
				}
			})
		.end()
		.find('img')
			.after(
				$('<a href="#" />')
				.addClass('fullscreen fa fa-expand')
				.on('click', function(ev){
					ev.preventDefault();
					ev.stopImmediatePropagation();
					if ( $('body').hasClass('in-fullscreen') ) {
						leaveFullscreen();							
					} else {
						enterFullscreen( $(this).parent()[0] );
					}
				})
			)
		.end()
		.find('video, audio')
			.each(function(idx, el){
				el.pause();
				el.currentTime = 0;
				el.volume = 0.2;

				var $el = $( el );

				el.onpause = function() {
					$el.next().addClass('paused').find('.play').toggleClass('fa-play fa-pause');
				};
				el.onplay = function() {
					$el.next().removeClass('paused').find('.play').removeClass('fa-undo').toggleClass('fa-play fa-pause');
				};
				el.onended = function() {
					$el.next().find('.play').addClass('fa-undo');
				};
				el.ondurationchange = function() {
					var t = el.duration,
						w = 0,
						v = '';

					w = Math.floor(t / 3600);
					t -= (w * 3600);
					if (w>0) {
						$el.next().find('.time').data('show-hours', true)
						v = v + (w<10?'0':'') + w + ':';
					}

					w = Math.floor(t / 60);
					t -= (w * 60);
					v = v + (w<10?'0':'') + w + ':';
					
					w = Math.round(t);
					v = v + (w<10?'0':'') + w;

					$el.next()
					.find('.time').find('.duration')
						.html( v );
				};
				el.ontimeupdate = function() {
					var t = el.currentTime,
						w = 0,
						v = '';
					
					w = Math.floor(t / 3600);
					t -= (w * 3600);
					if ($el.next().find('.time').data('show-hours')) {
						v = v + (w<10?'0':'') + w + ':';
					}
					
					w = Math.floor(t / 60);
					t -= (w * 60);
					v = v + (w<10?'0':'') + w + ':';
					
					w = Math.round(t);
					v = v + (w<10?'0':'') + w;
					
					$el.next()
					.find('.time').find('.currentTime')
						.html( v )
					.end().end()
					.find('.position').find('div')
						.width( (t*100 / el.duration) + '%' );
				};
				el.onvolumechange = function() {
					$el.next()
					.find('.volume')
						.toggleClass('fa-volume-off', el.muted)
						.toggleClass('fa-volume-up', !el.muted)
					.end()
					.find('.volume-bar')
						.toggleClass('muted', el.muted)
						.find('div')
							.height( 100 * el.volume + '%');
				};

				$el.after(
					$('<div />')
					.addClass('controls paused')
					.on('click', function(ev){
						ev.preventDefault();
						ev.stopImmediatePropagation();
						$(this).find('.play').click();
					})
					.on('dblclick', function(ev){
						ev.preventDefault();
						ev.stopImmediatePropagation();
						$(this).find('.fullscreen').click();
					})
					.append(
						$('<a href="#" />').addClass('play fa fa-play')
							.on('click', function(ev){
								ev.preventDefault();
								ev.stopImmediatePropagation();
								if (el.paused) {
									el.play();
								} else {
									el.pause();
								}
							})
							.on('focus', function(){
								$(window).on('keydown.video',function(ev2){
									if ( ev2.which == 13 ) {
										ev2.preventDefault();
										ev2.stopImmediatePropagation();
										if (el.paused) {
											el.play();
										} else {
											el.pause();
										}
									} else {
										mod_media_keys_handler( el, ev2 );
									}
								});
							})
							.on('blur', function(){
								$(window).off('.video');
							})
					)
					.append(
						$('<a href="#" />').addClass('fullscreen fa fa-expand')
							.on('click', function(ev){
								ev.preventDefault();
								ev.stopImmediatePropagation();
								if ( $('body').hasClass('in-fullscreen') ) {
									leaveFullscreen();							
								} else {
									enterFullscreen( $(el).parent().toggleClass('in-fullscreen')[0] );
									$(window).on('keydown.fullscreen-video',function(ev2){
										if ( ev2.which == 13 ) {
											ev2.preventDefault();
											ev2.stopImmediatePropagation();
											if (el.paused) {
												el.play();
											} else {
												el.pause();
											}
										} else {
											mod_media_keys_handler( el, ev2 );
										}
									});
								}
							})
							.on('focus', function(){
								$(window).on('keydown.video',function(ev2){
									mod_media_keys_handler( el, ev2 );
								});
							})
							.on('blur', function(){
								$(window).off('.video');
							})
					)
					.append(
						$('<div />').addClass('time')
							.html('<div class="duration" /><div class="currentTime" />')
					)
					.append(
						$('<a href="#" />').addClass('position')
							.html('<div/>')
							.on('click', function(ev){
								ev.preventDefault();
								ev.stopImmediatePropagation();
								var x = ev.pageX - $(this).offset().left,
									w = parseInt($(this).width());
								el.currentTime = Math.max(0, Math.min(1, x/w)) * el.duration;
							})
							.on('focus', function(){
								$(window).on('keydown.video',function(ev2){
									if ( ev2.which == 13 ) {
										ev2.preventDefault();
										ev2.stopImmediatePropagation();
										if (el.paused) {
											el.play();
										} else {
											el.pause();
										}
									} else {
										mod_media_keys_handler( el, ev2 );
									}
								});
							})
							.on('blur', function(){
								$(window).off('.video');
							})
					)
					.append(
						$('<a href="#" />').addClass('volume-bar')
							.html('<div/>')
							.on('click', function(ev){
								ev.preventDefault();
								ev.stopImmediatePropagation();
								el.muted = false;
								var y = ev.pageY - $(this).offset().top,
									h = parseInt($(this).height());
								el.volume = Math.max(0, Math.min(1, (h-y)/h));
							})
							.on('focus', function(){
								$(window).on('keydown.video',function(ev2){
									if ( ev2.which == 13 ) {
										ev2.preventDefault();
										ev2.stopImmediatePropagation();
										if (el.paused) {
											el.play();
										} else {
											el.pause();
										}
									} else {
										mod_media_keys_handler( el, ev2 );
									}
								});
							})
							.on('blur', function(){
								$(window).off('.video');
							})
					)
					.append(
						$('<a href="#" />').addClass('volume fa fa-volume-up')
							.on('click', function(ev){
								ev.preventDefault();
								ev.stopImmediatePropagation();
								el.muted = !el.muted;
							})
							.on('focus', function(){
								$(window).on('keydown.video',function(ev2){
									mod_media_keys_handler( el, ev2 );
								});
							})
							.on('blur', function(){
								$(window).off('.video');
							})
					)
				);
			})
		.end();

	}
	
}
