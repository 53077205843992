Array.prototype.last = function () {
    return this[this.length - 1];
};
Array.prototype.min = function () {
	return this.reduce(function(a, b) { return Math.min(a, b); })
}
Array.prototype.max = function () {
	return this.reduce(function(a, b) { return Math.max(a, b); })
}

function positionFooter() {
	var $f = $('footer');
	if ( $f && $f.length > 0 ) {
		$f.css('margin-top', '0px');
		// var $o = $('#page div.block-content').last();
		// var y = parseInt( ( $o.offset() ).top );
		// var h = parseInt( $o.outerHeight() );
		// var fh = parseInt( $f.outerHeight() );
		var hh = $('html').outerHeight();
		var wh = $( window ).outerHeight();

		if ( wh > hh ) {
			$f.css('margin-top', (wh-hh) + 'px');
		}
	}
}



var page_is_scrolling = false,
	page_no_scroll = false,
	page_anim_object = {scroll_y:0},
	page_ys = [],
	win_h = 0,
	body_h = 0,
	base_href = '';

function goNavigate(url) {
	var slug = url.replace(/\\/, '/').replace(base_href, '').split('?')[0];
	if (0 == slug.length) {
		slug = '/';
	}
	if ( slug != $('section.page.on').data('slug') ) {
		// Get pID
		var pID = 0;
		var $s = $('section.page').filter(function(idx, el) {
			return ($(el).data('slug') == slug);
		});
		if (0 < $s.length) {
			if (page_is_scrolling) {
				$( page_anim_object ).stop(true, true);
				page_is_scrolling = false;
			}
			History.pushState({
				id: $s.data('id'),
				title: $s.data('title'),
				keywords: $s.data('keywords'),
				description: $s.data('description'),
				slug: $s.data('slug'),
				//scroll: true,
			}, $s.data('title'), $('base[href]').attr('href') + ($s.data('slug')=='/'?'':$s.data('slug'))); // logs {state:1}, "State 1", "?state=1"
		}
	}
}

function handleStateChange() {
	var State = History.getState();

	// Highlight menu item & section
	$('#menu li, section.page').removeClass('on');
	$('#menu a[data-page="' + State.data.id + '"]').parent().addClass('on');
	$('section.page[data-id="' + State.data.id + '"]').addClass('on');

	if ( ! page_no_scroll ) { //State.data.scroll
		if (body_h > win_h) {
			// Scroll to active section
			var y = ($('section.page.on').index()==0)? 0: parseInt(($('section.page.on').find('.page-content').offset()).top)-80;
			page_anim_object.scroll_y = window.pageYOffset;
			$( page_anim_object ).animate({scroll_y:y}, {
				duration: 500,
				easing: 'swing',
				step: function(val) {
					window.scrollTo(0, val);
				},
				start: function(val) {
					page_is_scrolling = true;
				},
				done: function(val) {
					page_is_scrolling = false;
				},
			});
		}
	} else {
		page_no_scroll = false;
	}
}

function updateStateOnScroll() {
	if ( ! page_is_scrolling && body_h > win_h ) {
		var cur_y = window.pageYOffset;
		var line_y = cur_y + (win_h / 2);
		var max_y = body_h - win_h;

		var a = $.grep(page_ys, function( y, idx ) {
			return (y < line_y || (cur_y+5) >= max_y);
		}).length;
		var $s = $('section.page:nth-child(' + a + ')');
		if ( ! $s.hasClass('on') ) {
			page_no_scroll = true;
			History.pushState({
				id: $s.data('id'),
				title: $s.data('title'),
				keywords: $s.data('keywords'),
				description: $s.data('description'),
				slug: $s.data('slug'),
				//scroll: false,
			}, $s.data('title'), $('base[href]').attr('href') + ($s.data('slug')=='/'?'':$s.data('slug'))); // logs {state:1}, "State 1", "?state=1"
		}
	}
}

(function(window,undefined){
	// Prepare
	var History = window.History; // Note: We are using a capital H instead of a lower h
	if ( !History.enabled ) {
		 // History.js is disabled for this browser.
		 // This is because we can optionally choose to support HTML4 browsers or not.
		return false;
	}

	// Bind to StateChange Event
	History.Adapter.bind(window,'statechange',function(e){
		handleStateChange();
	});
})(window);


$( document ).ready(function(){

	// Mobile menu toggle
	$('nav')
		.find('> a.menu-toggle')
			.on('click', function(e){
				e.preventDefault();
				e.stopImmediatePropagation();
				$('nav, body').toggleClass('open');
			})
		.end()
		.on('click', function(e){
			$('nav, body').removeClass('open');
		});

	// Update navigation coordinates
	page_ys = $('section.page')
		.map(function(idx, el) {
			return ( idx==0 ? 0 : parseInt(($(el).offset()).top) );
		})
		.get();
	body_h = parseInt($('body')[0].scrollHeight);
	win_h = parseInt($(window).height());
	base_href = new RegExp('^' + $('base[href]').attr('href') );

	// Navigation init
	$('main a, #menu a').on('click', function(e){
		var url = $( this ).attr('href');
		if ( (
			url.search(base_href) == 0
			|| url.search(/^([a-z0-9]+:[\/\\]{2}|(mailto|tel):)/i) < 0
		) && !url.match('\/bestanden\/') ) {
			e.preventDefault();
			goNavigate(url);
		}
	});
	$( window ).on('scroll', function() {
		updateStateOnScroll();
	});

	// Scroll to active section
	var y = ($('section.page.on').index()==0)? 0: parseInt(($('section.page.on').find('.page-content').offset()).top)-80;
	page_anim_object.scroll_y = window.pageYOffset;
	$( page_anim_object ).animate({scroll_y:y}, {
		duration: 500,
		easing: 'swing',
		step: function(val) {
			window.scrollTo(0, val);
		},
		start: function(val) {
			page_is_scrolling = true;
		},
		done: function(val) {
			page_is_scrolling = false;
		},
	});


	// Contact module
	$('#go_mod_contact').on('click', function(e){
		e.preventDefault();
		e.stopImmediatePropagation();
		$('#do_mod_contact').click();
	});


	// coursesignup module
	$('#go_mod_coursesignup').on('click', function(e){
		e.preventDefault();
		e.stopImmediatePropagation();
		$('#do_mod_coursesignup').click();
	});
	

	// Place footer
	$( window ).on('resize', function() {
		// positionFooter();

		// Update navigation coordinates
		page_ys = $('section.page')
			.map(function(idx, el) {
				return ( idx==0 ? 0 : parseInt(($(el).offset()).top) );
			})
			.get();
		body_h = parseInt($('body')[0].scrollHeight);
		win_h = parseInt($(window).height());
		updateStateOnScroll();
	});
	setTimeout(function(){
		// positionFooter();
		// $('#footer').animate({opacity: 1}, 150, 'swing');
	}, 1000);

});